<template>
  <div>
    <div class="q-mt-md q-gutter-sm">
      <q-btn color="white" text-color="black" label="Import" @click="showImport()"/>
      <q-btn color="white" text-color="black" label="Template" @click="downloadImport()"/>
      <q-btn color="red" text-color="white" label="Add to project" v-touch-hold:3000.mouse="addImportsToProject">
          <q-tooltip
            transition-show="flip-right"
            transition-hide="flip-left"
          >
            Hold for 3 sec.
          </q-tooltip>
      </q-btn>
      <q-btn color="red" text-color="white" label="Delete" v-touch-hold:3000.mouse="deleteImports">
          <q-tooltip
            transition-show="flip-right"
            transition-hide="flip-left"
          >
            Hold for 3 sec.
          </q-tooltip>
      </q-btn>
    </div>
    <q-table
      class="q-mt-md"
      title="Lines"
      :rows="lines"
      :columns="columnsLines"
      row-key="lineNumber"
      :filter="filter"
      :dense="true"
    >
    <template v-slot:top>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props">
            {{ props.row.id }}
          </q-td>
          <q-td key="dateDisplay" :props="props">
            <a
              class="text-primary"
              @click="showDialog(props.row.id)"
            >
              {{ props.row.dateDisplay }}
            </a>
          </q-td>
          <q-td key="lineNumber" :props="props">
            {{ props.row.lineNumber }}
          </q-td>
          <q-td key="inspectedFeet" :props="props">
            {{ props.row.inspectedFeet }}
          </q-td>
          <q-td key="isReversal" :props="props">
            <div v-if="props.row.isReversal == 1">US</div>
            <div v-else-if="props.row.isReversal == 0">DS</div>
          </q-td>
          <q-td key="inspectionType.name" :props="props">
            <q-badge v-if="props.row.inspectionType.name == 'Complete'" color="green">
              {{ props.row.inspectionType.name }}
            </q-badge>
            <q-badge v-if="props.row.inspectionType.name == 'Incomplete'" color="orange">
              {{ props.row.inspectionType.name }}
            </q-badge>
            <q-badge v-if="props.row.inspectionType.name == 'Aborted'" color="red">
              {{ props.row.inspectionType.name }}
            </q-badge>
          </q-td>
          <q-td key="details" :props="props">
            {{ props.row.details }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-table
      class="q-mt-md"
      title="Manholes"
      :rows="manholes"
      :columns="columnsManholes"
      :filter="filter"
      :dense="true"
    >
    <template v-slot:top>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
    </q-table>
    <q-table
      class="q-mt-md"
      title="Jettings"
      :rows="jettings"
      :columns="columnsJettings"
      row-key="lineNumber"
      :filter="filter"
      :dense="true"
    >
      <template v-slot:top>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
    </q-table>
    <q-dialog
      v-model="importDialog"
    >
      <q-uploader
        @uploaded="getImports"
        :url="aurl + 'api/CCTV/Imports/ProjectId/' + projectId"
        :headers="[{name: 'Authorization', value: getToken()}]"
        style="max-width: 300px"
      />
    </q-dialog>
  </div>
</template>

<script>

import axios from '../../axios'
import { useQuasar } from 'quasar'
import fileDownload from 'js-file-download'

const url = axios.defaults.baseURL

export default {
  name: 'Imports',
  components: {
  },
  props: {
  },
  data() {
    return {
      filter: '',
      aurl: url,
      lines: [],
      manholes: [],
      jettings: [],
      importDialog: false,
      projectId: this.$route.params.id,
      notify: useQuasar(),
      columnsLines: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'dateDisplay', align: 'center', label: 'Date', field: 'dateDisplay', sortable: true },
        { name: 'lineNumber', align: 'center', label: 'Number', field: 'lineNumber', sortable: true },
        { name: 'inspectedFeet', align: 'center', label: 'LF', field: 'inspectedFeet', sortable: true },
        { name: 'isReversal', align: 'center', label: 'Direction', field: 'isReversal', sortable: true },
        { name: 'inspectionType.name', align: 'center', label: 'Status', field: row => row.inspectionType.name, sortable: true },
        { name: 'details', align: 'left', label: 'Details', field: 'details', sortable: true },
      ],
      columnsManholes: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'dateDisplay', align: 'center', label: 'Date', field: 'dateDisplay', sortable: true },
        { name: 'manholeId', align: 'center', label: 'Number', field: 'manholeId', sortable: true },
        { name: 'inspectionType.name', align: 'center', label: 'Status', field: row => row.inspectionType.name, sortable: true },
        { name: 'details', align: 'left', label: 'Details', field: 'details', sortable: true },
      ],
      columnsJettings: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'dateDisplay', align: 'center', label: 'Date', field: 'dateDisplay', sortable: true },
        { name: 'lineNumber', align: 'center', label: 'Line Number', field: 'lineNumber', sortable: true },
        { name: 'timesJetted', align: 'center', label: 'TImes Jetted', field: 'timesJetted', sortable: true },
        { name: 'length', align: 'center', label: 'Length', field: 'length', sortable: true },
      ],
    }
  },
  created() {
    this.getImports()
  },
  methods: {
    async getImports() {
      await axios
        .get(`/api/CCTV/Imports/ProjectId/${this.projectId}`)
        .then(response => {
            this.lines = response.data.lines
            this.manholes = response.data.manholes
            this.jettings = response.data.jettings
        })
    },
    async addImportsToProject() {
      /* eslint-disable no-unused-vars */
        await axios
          .post(`/api/CCTV/Imports/AddToProject/ProjectId/${this.projectId}`, {})
          .then(response => {
            this.notify.notify({
              message: 'Items added to project',
              color: 'green'
            })
          })
        await this.getImports()
      /* eslint-enable no-unused-vars */
    },
    async deleteImports() {
      /* eslint-disable no-unused-vars */
      await axios
        .delete(`/api/CCTV/Imports/ProjectId/${this.projectId}`)
        .then(response => {
          this.notify.notify({
            message: 'Deleted',
            color: 'red'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getImports()
      /* eslint-enable no-unused-vars */
    },
    async showImport()
    {
      this.importDialog = true
    },
    getToken() {
      return localStorage.getItem("access_token");
    },
    async downloadImport() {
      await axios
        .get('/api/CCTV/Imports/ImportTemplate', {
          responseType: 'blob',
        })
        .then(res => {
          fileDownload(res.data, "Imports Template.xlsx")
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
    },
  },
}
</script>
