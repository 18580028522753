<template>
  <div>
    <q-card flat bordered class="my-card" v-if="stats !== null">
      <q-card-section>
        <div class="text-h6">Jettings - {{ stats.totalGisFeet }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <span>Cleaning: {{ stats.normal.lfDisplay }} ({{ stats.normal.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.normal.percentage" color="green" class="q-mt-sm q-mb-sm"/>
        
        <span>Recleaning: {{ stats.reCleaning.lfDisplay }} ({{ stats.reCleaning.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.reCleaning.percentage" color="orange" class="q-mt-sm q-mb-sm"/>
        
        <span>Heavy: {{ stats.heavy.lfDisplay }} ({{ stats.heavy.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.heavy.percentage" color="red" class="q-mt-sm q-mb-sm"/>

        <span>Project: {{ stats.project.lfDisplay }} / {{ stats.totalGisFeet }} ({{ stats.project.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.project.percentage" color="secondary" class="q-mt-sm q-mb-sm"/>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  name: 'StatsJettings',
  components: {
  },
  props: {
  },
  data() {
    return {
      projectId: this.$route.params.id,
      stats: null,
    }
  },
  computed: {
  },
  created() {
    this.getStats()
  },
  methods: {
    async getStats() {
      await axios
        .get(`/api/CCTV/Jettings/ProjectId/${this.projectId}/Statistics`)
        .then(response => {
          this.stats = response.data
        })
    }
  },
}
</script>