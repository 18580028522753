<template>
    <div>
    <q-table
      title="Daily Diaries"
      :rows="dailydiaries"
      :columns="columns"
      row-key="name"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
    >
    <template v-slot:top>
        <q-btn color="green" label="Add" @click="showDialog(0)"/>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props">
            {{ props.row.id }}
          </q-td>
          <q-td key="dateDisplay" :props="props">
            <a
              class="text-primary"
              @click="showDialog(props.row.id)"
            >
              {{ props.row.dateDisplay }}
            </a>
          </q-td>
          <q-td key="weather" :props="props">
            {{ props.row.weather }}
          </q-td>
          <q-td key="hoursCharged" :props="props">
            {{ props.row.hoursCharged }}
          </q-td>
          <q-td key="numberCrew" :props="props">
            {{ props.row.numberCrew }}
          </q-td>
          <q-td key="superIntendent" :props="props">
            {{ props.row.superIntendent }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="dailydiaryDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="entryPost()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
                <div class="col-3">
                    <q-input
                        v-model="dailydiary.dateDisplay"
                        :dense="true"
                        label="Date & Time"
                        outlined
                    />
                </div>
                <div class="col-3">
                    <q-input
                        v-model="dailydiary.weather"
                        :dense="true"
                        label="Weather"
                        outlined
                    />
                </div>
                <div class="col-3">
                    <q-input
                        v-model="dailydiary.hoursCharged"
                        :dense="true"
                        label="Hours Worked"
                        outlined
                    />
                </div>
                <div class="col-3">
                    <q-input
                        v-model="dailydiary.numberCrew"
                        :dense="true"
                        label="Number of Crew"
                        outlined
                    />
                </div>
                <div class="col-6">
                    <q-input
                        v-model="dailydiary.superIntendent"
                        :dense="true"
                        label="Super Intendent"
                        outlined
                    />
                </div>
                <div class="col-6">
                    <q-input
                        v-model="dailydiary.visitors"
                        :dense="true"
                        label="Visitors"
                        outlined
                    />
                </div>
                <div class="col-12">
                    <q-input
                        v-model="dailydiary.equipmentOnSite"
                        :dense="true"
                        label="Equipment On Site"
                        outlined
                    />
                </div>
                <div class="col-12">
                    <q-input
                        v-model="dailydiary.notes"
                        outlined
                        label="Notes"
                        type="textarea"
                    />
                </div>
            </div>
            <div class="q-mt-sm" />
            <div>
              <UserCard :user="dailydiary.applicationUser"/>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="entryDelete">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import axios from '../../axios'
import UserCard from '../util/UserCard.vue'


export default {
  name: 'DailyDiaries',
  components: {
    UserCard,
  },
  props: {
  },
  data() {
    return {
      notify: useQuasar(),
      dailydiaries: [],
      dailydiary: {},
      dailydiaryDialog: false,
      submitDisable: false,
      projectId: this.$route.params.id,
      filter: '',
      columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'dateDisplay', align: 'center', label: 'Date', field: 'dateDisplay', sortable: true },
        { name: 'weather', align: 'center', label: 'Weather', field: 'weather', sortable: true },
        { name: 'hoursCharged', align: 'center', label: 'Hours', field: 'hoursCharged', sortable: true },
        { name: 'numberCrew', align: 'center', label: 'Crew', field: 'numberCrew', sortable: true },
        { name: 'superIntendent', align: 'center', label: 'SI', field: 'superIntendent', sortable: true },
      ],
    }
  },
  computed: {
  },
  created() {
    this.getDailyDiarys()
  },
  methods: {
    async getDailyDiarys() {
      axios
        .get(`/api/CCTV/DailyDiarys/ProjectId/${this.projectId}`)
        .then(response => { this.dailydiaries = response.data })
    },
    async getDailyDiary(id) {
      await axios
        .get(`/api/CCTV/DailyDiarys/Id/${id}`)
        .then(response => { this.dailydiary = response.data })
    },
    async showDialog(id) {
      await this.getDailyDiary(id)
      this.dailydiaryDialog = true
    },
    async entryPost() {
      this.dailydiary.projectId = this.projectId
      this.submitDisable = true
      await axios
        .post('/api/CCTV/DailyDiarys', this.dailydiary)
        .then(response => {
          this.dailydiary = response.data
          this.notify.notify({
            message: 'Saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      this.submitDisable = false
      await this.getDailyDiarys()
    },
    async entryDelete() {
      /* eslint-disable no-unused-vars */
      await axios
        .delete(`/api/CCTV/DailyDiarys/Id/${this.dailydiary.id}`)
        .then(response => {
          this.notify.notify({
            message: 'Entry Deleted',
            color: 'green'
          })
          this.dailydiaryDialog = false
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getDailyDiarys()
      /* eslint-enable no-unused-vars */
    },
  },
}
</script>