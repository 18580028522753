<template>
  <div>
    <q-table
      title="Lines"
      :rows="lines"
      :columns="columns"
      row-key="name"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
    >
    <template v-slot:top>
        <q-btn color="green" label="Add" @click="showDialog(0)"/>
        <q-btn class="q-ml-sm" color="green" label="Complete" @click="getLinesInState('Complete')"/>
        <q-btn class="q-ml-sm" color="orange" label="Incomplete" @click="getLinesInState('Incomplete')"/>
        <q-btn class="q-ml-sm" color="red" label="Aborted" @click="getLinesInState('Aborted')"/>
        <q-btn class="q-ml-sm" color="dark" label="Records" @click="getLines()"/>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props">
            {{ props.row.id }}
          </q-td>
          <q-td key="dateDisplay" :props="props">
            <a
              class="text-primary"
              @click="showDialog(props.row.id)"
            >
              {{ props.row.dateDisplay }}
            </a>
          </q-td>
          <q-td key="lineNumber" :props="props">
            {{ props.row.lineNumber }}
          </q-td>
          <q-td key="isReversal" :props="props">
            <div v-if="props.row.isReversal == 1">US</div>
            <div v-else-if="props.row.isReversal == 0">DS</div>
          </q-td>
          <q-td key="inspectedFeet" :props="props">
            {{ props.row.inspectedFeet }}
          </q-td>
          <q-td key="inspectionType.name" :props="props">
            <q-badge v-if="props.row.inspectionType.name == 'Complete'" color="green">
              {{ props.row.inspectionType.name }}
            </q-badge>
            <q-badge v-if="props.row.inspectionType.name == 'Incomplete'" color="orange">
              {{ props.row.inspectionType.name }}
            </q-badge>
            <q-badge v-if="props.row.inspectionType.name == 'Aborted'" color="red">
              {{ props.row.inspectionType.name }}
            </q-badge>
          </q-td>
          <q-td key="details" :props="props">
            {{ props.row.details }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="lineDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postLine()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="line.dateDisplay"
                  :dense="true"
                  label="Date & Time"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="line.lineNumber"
                  :dense="true"
                  label="Line Number"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="line.inspectedFeet"
                  :dense="true"
                  label="LF"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select label="Status" outlined emit-value map-options :dense="true" v-model="line.inspectionTypeId" :options="inspectionTypes" />
              </div>
              <div class="col-4">
                <q-select label="Direction" outlined emit-value map-options :dense="true" v-model="line.isReversal" :options="reversalOptions" />
              </div>
              <div class="col-12">
                <q-input
                  v-model="line.details"
                  outlined
                  type="textarea"
                />
              </div>
            </div>
            <div class="q-mt-sm" />
            <div>
              <UserCard :user="line.applicationUser"/>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="entryDelete">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>

import axios from '../../axios'
import { useQuasar } from 'quasar'
import UserCard from '../util/UserCard.vue'

export default {
  name: 'Lines',
  components: {
    UserCard,
  },
  props: {
  },
  data() {
    return {
      reversalOptions: [
        { value: false, label: 'DS' },
        { value: true, label: 'US' },
      ],
      filter: '',
      line: {},
      lines: [],
      submitDisable: false,
      lineDialog: false,
      inspectionTypes: [],
      projectId: this.$route.params.id,
      notify: useQuasar(),
      columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'dateDisplay', align: 'center', label: 'Date', field: 'dateDisplay', sortable: true },
        { name: 'lineNumber', align: 'center', label: 'Number', field: 'lineNumber', sortable: true },
        { name: 'isReversal', align: 'center', label: 'Direction', field: 'isReversal', sortable: true },
        { name: 'inspectedFeet', align: 'center', label: 'LF', field: 'inspectedFeet', sortable: true },
        { name: 'inspectionType.name', align: 'center', label: 'Status', field: row => row.inspectionType.name, sortable: true },
        { name: 'details', align: 'left', label: 'Details', field: 'details', sortable: true },
      ],
    }
  },
  created() {
    this.getLines()
    this.getInspectionTypes()
  },
  methods: {
    async getLines() {
      axios
        .get(`/api/CCTV/Lines/ProjectId/${this.projectId}`)
        .then(response => { this.lines = response.data })
    },
    async getLine(lineId) {
      await axios
        .get(`/api/CCTV/Lines/Id/${lineId}`)
        .then(response => { this.line = response.data })

    },
    async showDialog(lineId) {
      await this.getLine(lineId)
      this.lineDialog = true
    },
    getInspectionTypes() {
      axios
        .get('/api/CCTV/InspectionTypes/Type/line')
        .then(response => {
          response.data.forEach(element => this.inspectionTypes.push({ value: element.id, label: element.name }))
        })
    },
    async postLine() {
      this.line.projectId = this.projectId
      this.submitDisable = true
      await axios
        .post('/api/CCTV/Lines', this.line)
        .then(response => {
          this.line = response.data
          this.notify.notify({
            message: 'Saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      this.submitDisable = false
      await this.getLines()
    },
    async entryDelete() {
      /* eslint-disable no-unused-vars */
      await axios
        .delete(`/api/CCTV/Lines/Id/${this.line.id}`)
        .then(response => {
          this.lineDialog = false
          this.notify.notify({
            message: 'Deleted',
            color: 'red'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getLines()
      /* eslint-enable no-unused-vars */
    },
    getLinesInState(state) {
      axios
        // eslint-disable-next-line
        .get(`/api/CCTV/Lines/ProjectId/${this.projectId}/${state}`)
        .then(response => {
          this.lines = response.data
        })
    },
  },
}
</script>
