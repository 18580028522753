<template>
    <div>
    <q-table
      title="GIS Manholes"
      :rows="gismanholes"
      :columns="columns"
      row-key="name"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
    >
    <template v-slot:top>
        <q-btn color="green" label="Add" @click="showDialog(0)"/>
        <q-btn class="q-ml-sm" color="green" label="Import" @click="showImportDialog()"/>
        <q-btn class="q-ml-sm" color="green" label="Template" @click="downloadImport()"/>
        <q-btn class="q-ml-sm" color="red" label="Delete" v-touch-hold:5000.mouse="deleteAllGis">
          <q-tooltip
            transition-show="flip-right"
            transition-hide="flip-left"
          >
            Hold for 5 sec.
          </q-tooltip>
        </q-btn>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props">
            {{ props.row.id }}
          </q-td>
          <q-td key="quarterSection" :props="props">
            {{ props.row.quarterSection }}
          </q-td>
          <q-td key="facilityId" :props="props">
            <a
              class="text-primary"
              @click="showDialog(props.row.id)"
            >
              {{ props.row.facilityId }}
            </a>
          </q-td>
          <q-td key="gisMaterial" :props="props">
            {{ props.row.gisMaterial }}
          </q-td>
          <q-td key="lat" :props="props">
            {{ props.row.lat }}
          </q-td>
          <q-td key="long" :props="props">
            {{ props.row.long }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="gismanholeDialog"
    >
      <q-card style="width: 500px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postGisManhole()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-6">
                <q-input
                  v-model="gismanhole.quarterSection"
                  :dense="true"
                  label="Quarter Section"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gismanhole.facilityId"
                  :dense="true"
                  label="Facility Id"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gismanhole.gisMaterial"
                  :dense="true"
                  label="Material"
                  outlined
                />
              </div>
              <div class="col-6" />
              <div class="col-6">
                <q-input
                  v-model="gismanhole.long"
                  :dense="true"
                  label="Long"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gismanhole.lat"
                  :dense="true"
                  label="Lat"
                  outlined
                />
              </div>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="entryDelete">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="importDialog"
    >
      <q-uploader
        @uploaded="getGisManholes"
        :url="baseUrl + 'api/CCTV/GISManholes/Import/ProjectId/' + projectId"
        :headers="[{name: 'Authorization', value: getToken()}]"
        style="max-width: 300px"
      />
    </q-dialog>
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import axios from '../../axios'
import fileDownload from 'js-file-download'

const url = axios.defaults.baseURL

export default {
  name: 'GISManholes',
  components: {
  },
  props: {
  },
  data() {
    return {
      notify: useQuasar(),
      gismanholes: [],
      gismanhole: {},
      baseUrl: url,
      gismanholeDialog: false,
      importDialog: false,
      projectId: this.$route.params.id,
      filter: '',
      columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'quarterSection', align: 'center', label: 'QS', field: 'quarterSection', sortable: true },
        { name: 'facilityId', align: 'center', label: 'Facility Id', field: 'facilityId', sortable: true },
        { name: 'gisMaterial', align: 'center', label: 'Material', field: 'gisMaterial', sortable: true },
        { name: 'lat', align: 'center', label: 'lat', field: 'lat', sortable: true },
        { name: 'long', align: 'center', label: 'Long', field: 'long', sortable: true },
      ],
    }
  },
  computed: {
  },
  created() {
    this.getGisManholes()
  },
  methods: {
    async getGisManholes() {
      axios
        .get(`/api/CCTV/GISManholes/ProjectId/${this.projectId}`)
        .then(response => { this.gismanholes = response.data })
    },
    async getGisManhole(id) {
        if (id !== 0) {
          axios
            .get(`/api/CCTV/GISManholes/Id/${id}`)
            .then(response => { this.gismanhole = response.data })
        } else {
            this.gismanhole = {}
        }
    },
    showDialog(manholeId) {
      this.getGisManhole(manholeId)
      this.gismanholeDialog = true
    },
    async postGisManhole() {
      this.gismanhole.projectId = this.projectId
      await axios
        .post('/api/CCTV/GISManholes', this.gismanhole)
        .then(response => {
          this.gismanhole = response.data
          this.notify.notify({
            message: 'Work saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getGisManholes()
    },
    async entryDelete() {
      /* eslint-disable no-unused-vars */
      await axios
        .delete(`/api/CCTV/GISManholes/Id/${this.gismanhole.id}`)
        .then(response => {
          this.notify.notify({
            message: 'Entry Deleted',
            color: 'green'
          })
          this.gismanholeDialog = false
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getGisManholes()
      /* eslint-enable no-unused-vars */
    },
    async deleteAllGis() {
      await axios
        .delete(`/api/CCTV/GISManholes/ProjectId/${this.projectId}`)
        .then(response => {
          console.log(response)
          this.notify.notify({
            message: 'Data Deleted',
            color: 'green'
          })
        })
      await this.getGisManholes()
    },
    showImportDialog() {
      this.importDialog = true
    },
    getToken() {
      return localStorage.getItem("access_token");
    },
    async downloadImport() {
      await axios
        .get('/api/CCTV/GISManholes/ImportTemplate', {
          responseType: 'blob',
        })
        .then(res => {
          fileDownload(res.data, "GIS Manholes Import Template.xlsx")
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
    },
  },
}
</script>