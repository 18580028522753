<template>
  <div class="row q-col-gutter-sm q-pa-md">
    <div class="col-4"></div>
    <q-card class="my-card col-4">
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-12">
              <q-input
                v-model="report.dateStart"
                :dense="true"
                label="Start Date"
                outlined
              />
            </div>
            <div class="col-12">
              <q-input
                v-model="report.dateEnd"
                :dense="true"
                label="End Date"
                outlined
              />
            </div>
            <div class="col-12 text-center">
              <q-btn color="black" :loading="loading" :disable="loading" class="full-width text-weight-light" label="Daily Report" @click="getDailyDiary()"/>
              <q-btn color="black" :loading="loading" :disable="loading" class="full-width text-weight-light q-mt-xs" label="Weekly Report" @click="getWeeklyReport()"/>
              <q-btn color="black" :loading="loading" :disable="loading" class="full-width text-weight-light q-mt-xs" label="Line Progress Report" @click="getLineProgress()"/>
              <q-btn color="black" :loading="loading" :disable="loading" class="full-width text-weight-light q-mt-xs" label="Manhole Progress Report" @click="getManholeProgress()" />
              <q-btn color="black" :loading="loading" :disable="loading" class="full-width text-weight-light q-mt-xs" label="Line Records" @click="getLineRecords()"/>
              <q-btn color="black" :loading="loading" :disable="loading" class="full-width text-weight-light q-mt-xs" label="Manholes Records" @click="getManholeRecords()"/>
              <q-btn color="black" :loading="loading" :disable="loading" class="full-width text-weight-light q-mt-xs" label="Jetting Records" @click="getJettingRecords()"/>
            </div>
        </div>
      </q-card-section>
    </q-card>
    <div class="col-4"></div>
  </div>
</template>
<script>
import axios from '../../axios'
import fileDownload from 'js-file-download'
import { useQuasar } from 'quasar'

export default {
  name: 'Exports',
  components: {
  },
  props: {
  },
  data() {
    return {
      notify: useQuasar(),
      report: {},
      loading: false,
      projectId: this.$route.params.id,
    }
  },
  created() {
  },
  methods: {
    async getDailyDiary() {
      this.loading = true
      await this.download(`/api/CCTV/Reports/DailyDiary/ProjectId/${this.projectId}/Date/${this.report.dateStart}`)
      this.loading = false
    },
    async getWeeklyReport() {
      this.loading = true
      await this.download(`/api/CCTV/Reports/WeeklyReport/ProjectId/${this.projectId}/Date/${this.report.dateStart}`)
      this.loading = false
    },
    async getLineProgress() {
      this.loading = true
      await this.download(`/api/CCTV/Reports/LineProgress/ProjectId/${this.projectId}`)
      this.loading = false
    },
    async getManholeProgress() {
      this.loading = true
      await this.download(`/api/CCTV/Reports/ManholeProgress/ProjectId/${this.projectId}`)
      this.loading = false
    },
    async getLineRecords() {
      this.loading = true
      await this.download(`/api/CCTV/Reports/RecordsLines/ProjectId/${this.projectId}`)
      this.loading = false
    },
    async getManholeRecords() {
      this.loading = true
      await this.download(`/api/CCTV/Reports/RecordsManholes/ProjectId/${this.projectId}`)
      this.loading = false
    },
    async getJettingRecords() {
      this.loading = true
      await this.download(`/api/CCTV/Reports/RecordsJettings/ProjectId/${this.projectId}`)
      this.loading = false
    },
    async download(url) {
      await axios
        .get(url, {
          responseType: 'blob',
        })
        .then(res => {
          const strData = res.headers['content-disposition']
          const indexStart = strData.indexOf('filename', 0)
          const indexEnd = strData.indexOf(';', indexStart)
          const filestr = strData.substring(indexStart, indexEnd)
          var filename = filestr.split('=')[1]
          const newName = filename.replaceAll('"','')
          fileDownload(res.data, newName)
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
    },
  },
}
</script>