<template>
    <div>
    <q-table
      title="Manholes"
      :rows="manholes"
      :columns="columns"
      row-key="name"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
    >
    <template v-slot:top>
        <q-btn color="green" label="Add" @click="showDialog(0)"/>
        <q-btn class="q-ml-sm" color="green" label="Scanned" @click="getManholesInState('Scanned')"/>
        <q-btn class="q-ml-sm" color="green" label="Rescanned" @click="getManholesInState('Rescanned')"/>
        <q-btn class="q-ml-sm" color="orange" label="Need Rescan" @click="getManholesInState('NeedRescan')"/>
        <q-btn class="q-ml-sm" color="orange" label="Need CTY" @click="getManholesInState('NeedCTY')"/>
        <q-btn class="q-ml-sm" color="red" label="Aborted" @click="getManholesInState('Aborted')"/>
        <q-btn class="q-ml-sm" color="dark" label="Records" @click="getManholes()"/>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props">
            {{ props.row.id }}
          </q-td>
          <q-td key="dateDisplay" :props="props">
            <a
              class="text-primary"
              @click="showDialog(props.row.id)"
            >
              {{ props.row.dateDisplay }}
            </a>
          </q-td>
          <q-td key="manholeId" :props="props">
            {{ props.row.manholeId }}
          </q-td>
          <q-td key="inspectionType.name" :props="props">
            <q-badge :color="statusManhole(props.row.inspectionType.name)">
              {{ props.row.inspectionType.name }}
            </q-badge>
          </q-td>
          <q-td key="details" :props="props">
            {{ props.row.details }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="manholeDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postManhole()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="manhole.dateDisplay"
                  :dense="true"
                  label="Date & Time"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="manhole.manholeId"
                  :dense="true"
                  label="Manhole Number"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-select label="Status" outlined emit-value map-options :dense="true" v-model="manhole.inspectionTypeId" :options="inspectionTypes" />
              </div>
              <div class="col-12">
                <q-input
                  v-model="manhole.details"
                  outlined
                  type="textarea"
                />
              </div>
            </div>
            <div class="q-mt-sm" />
            <div>
              <UserCard :user="manhole.applicationUser"/>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="entryDelete">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>

import axios from '../../axios'
import { useQuasar } from 'quasar'
import UserCard from '../util/UserCard.vue'

export default {
  name: 'Manholes',
  components: {
    UserCard,
  },
  props: {
  },
  data() {
    return {
      notify: useQuasar(),
      manholes: [],
      manhole: {},
      submitDisable: false,
      manholeDialog: false,
      inspectionTypes: [],
      filter: '',
      projectId: this.$route.params.id,
      columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'dateDisplay', align: 'center', label: 'Date', field: 'dateDisplay', sortable: true },
        { name: 'manholeId', align: 'center', label: 'Number', field: 'manholeId', sortable: true },
        { name: 'inspectionType.name', align: 'center', label: 'Status', field: row => row.inspectionType.name, sortable: true },
        { name: 'details', align: 'left', label: 'Details', field: 'details', sortable: true },
      ],
    }
  },
  computed: {
  },
  created() {
    this.getManholes()
    this.getInspectionTypes()
  },
  methods: {
    statusManhole(input) {
      switch (input) {
        case 'Complete - Scanned': return 'green'
        case 'Complete - Rescanned': return 'green'
        case 'Incomplete - Need Rescan': return 'orange'
        case 'Incomplete - Need CTY': return 'orange'
        case 'Aborted': return 'red'
        default: return 'dark'
      }
    },
    async getManholes() {
      axios
        .get(`/api/CCTV/Manholes/ProjectId/${this.projectId}`)
        .then(response => { this.manholes = response.data })
    },
    async getManhole(id) {
      await axios
        .get(`/api/CCTV/Manholes/Id/${id}`)
        .then(response => { this.manhole = response.data })
    },
    async showDialog(manholeId) {
      await this.getManhole(manholeId)
      this.manholeDialog = true
    },
    getInspectionTypes() {
      axios
        .get('/api/CCTV/InspectionTypes/Type/manhole')
        .then(response => {
          response.data.forEach(element => this.inspectionTypes.push({ value: element.id, label: element.name }))
        })
    },
    async postManhole() {
      this.manhole.projectId = this.projectId
      this.submitDisable = true
      await axios
        .post('/api/CCTV/Manholes', this.manhole)
        .then(response => {
          this.manhole = response.data
          this.notify.notify({
            message: 'Saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      this.submitDisable = false
      await this.getManholes()
    },
    async entryDelete() {
      /* eslint-disable no-unused-vars */
      await axios
        .delete(`/api/CCTV/Manholes/Id/${this.manhole.id}`)
        .then(response => {
          this.manholeDialog = false
          this.notify.notify({
            message: 'Entry deleted',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getManholes()
      /* eslint-enable no-unused-vars */
    },
    async getManholesInState(state) {
      axios
        .get(`/api/CCTV/Manholes/ProjectId/${this.projectId}/${state}`)
        .then(response => {
          this.manholes = response.data
        })
    },
  },
}
</script>