<template>
  <div>
    <q-card flat bordered class="my-card" v-if="stats !== null">
      <q-card-section>
        <div class="text-h6">Lines - {{ stats.gis.count }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <span>Complete: {{ stats.complete.count }} ({{ stats.complete.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.complete.percentage" color="green" class="q-mt-sm q-mb-sm"/>
        
        <span>Incomplete: {{ stats.incomplete.count }} ({{ stats.incomplete.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.incomplete.percentage" color="orange" class="q-mt-sm q-mb-sm"/>
        
        <span>Aborted: {{ stats.aborted.count }} ({{ stats.aborted.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.aborted.percentage" color="red" class="q-mt-sm q-mb-sm"/>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  name: 'StatsLines',
  components: {
  },
  props: {
  },
  data() {
    return {
      projectId: this.$route.params.id,
      stats: null,
    }
  },
  computed: {
  },
  created() {
    this.getStats()
  },
  methods: {
    async getStats() {
      await axios
        .get(`/api/CCTV/Lines/ProjectId/${this.projectId}/Statistics`)
        .then(response => {
          this.stats = response.data
        })
    }
  },
}
</script>