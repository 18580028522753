<template>
    <div class="q-pa-md">
        <q-card class="my-card">
          <q-card-section>
              <div class="text-h6">{{ project.projectNumber }} - {{ project.projectName }}</div>
              <div class="text-subtitle2">{{ project.projectLocation }}</div>
          </q-card-section>
          <q-separator />
          <q-tabs
            v-model="tab"
            dense
            align="left"
            class="bg-primary text-white shadow-2"
            :breakpoint="0"
          >
            <q-tab name="overview" label="Overview"/>
            <q-tab name="reports" label="Reports"/>
            <q-tab name="lines" label="Lines"/>
            <q-tab name="manholes" label="Manholes"/>
            <q-tab name="jettings" label="Jettings"/>
            <q-tab name="gislines" label="GIS Lines"/>
            <q-tab name="gismanholes" label="GIS Manholes"/>
            <q-tab name="map" label="Map"/>
            <q-tab v-if="isInRole('Inspector')" name="imports" label="Imports"/>
            <q-tab name="exports" label="Exports"/>
          </q-tabs>
        </q-card>

        <div class="q-mt-md">
          <div v-if="tab === 'overview'" >
            <div class="row q-col-gutter-x-md">
                <div class="col-4">
                  <Statsmanholes />
                </div>
                <div class="col-4">
                  <StatsLines />
                </div>
                <div class="col-4">
                  <StatsJetting />
                </div>
            </div>
          </div>

          <Lines v-if="tab === 'lines'"/>
          <Manholes v-if="tab === 'manholes'"/>
          <GisLines v-if="tab === 'gislines'"/>
          <GisManholes v-if="tab === 'gismanholes'"/>
          <DailyDiaries v-if="tab === 'reports'"/>
          <Jettings v-if="tab === 'jettings'"/>
          <Imports v-if="tab === 'imports'"/>
          <Exports v-if="tab === 'exports'"/>
          <Map v-if="tab === 'map'" />
        </div>
    </div>
</template>

<script>
import axios from '../axios'
import Lines from '../components/cctv/Lines.vue'
import Manholes from '../components/cctv/Manholes.vue'
import GisLines from '../components/cctv/GisLines.vue'
import GisManholes from '../components/cctv/GisManholes.vue'
import DailyDiaries from '../components/cctv/DailyDiaries.vue'
import Jettings from  '../components/cctv/Jettings.vue'
import Exports from '../components/cctv/Exports.vue'
import Statsmanholes from '../components/cctv/StatsManholes.vue'
import StatsLines from '../components/cctv/StatsLines.vue'
import StatsJetting from '../components/cctv/StatsJettings.vue'
import Map from'../components/cctv/Map.vue'
import Imports from '../components/cctv/Imports.vue'
import UtilMixin from '../UtilMixin'

export default {
  components: {
    Lines,
    Manholes,
    GisLines,
    GisManholes,
    DailyDiaries,
    Jettings,
    Exports,
    Statsmanholes,
    StatsLines,
    StatsJetting,
    Imports,
    Map,
  },
  mixins: [
    UtilMixin
  ],
  data() {
    return {
      projects: [],
      project: {},
      projectId: this.$route.params.id,
      tab: 'overview',
    }
  },
  created() {
    axios.get(`/api/Projects/Id/${this.projectId}`)
      .then(response => {
        this.project = response.data
      })
  },
  methods: {
  }
}
</script>