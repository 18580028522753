<template>
  <div>
    <div id="mapid" style="height: 600px; position: relative; outline: none;">
    </div>
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import axios from '../../axios'
import "leaflet/dist/leaflet.css"
import leaflet from 'leaflet'

export default {
  name: 'Map',
  components: {
  },
  props: {
  },
  data() {
    return {
      notify: useQuasar(),
      projectId: this.$route.params.id,
      map: {},
    }
  },
  computed: {
  },
  mounted() {
    this.initMap()
    this.getLines()
  },
  methods: {
    initMap() {
      this.map = leaflet.map('mapid').setView([39.04068, -95.72859], 13);
      leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map)
    },
    async getLines() {
      await axios.get(`/api/CCTV/Maps/ProjectId/${this.projectId}`)
      .then(res => {
        var lines = res.data.lineMaps
        var manholes = res.data.manholeMaps

        lines.forEach(element => {
          const lattings = [
            element.dsMh,
            element.usMh
          ]

          leaflet
            .polyline(lattings, {color: element.status.color})
            .addTo(this.map)
            .bindPopup(`Line: ${element.lineNumber}
            <br/> Status: ${element.status.name}
            <br/> Diameter: ${element.gisDiameter}"
            <br/> Length: ${element.gisLength}LF
            <br/> DSMH: ${element.gisDsMh}
            <br/> USMH: ${element.gisUsMh}
            <br/> Material: ${element.gisMaterial}
            <br/> ROW: ${element.gisRowStatus}
          `);
        });

        manholes.forEach(element => {
          leaflet
            .circleMarker([element.lat, element.long], { radius: 2, color: element.status.color, fillColor: element.status.color })
            .addTo(this.map)
            .bindPopup(`MH: ${element.facilityId}
            <br/> Status: ${element.status.name}
            <br/> ROW: ${element.gisRowStatus}
          `);
        })
        
      })
    },
  },
}
</script>