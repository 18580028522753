<template>
    <div>
    <q-table
      title="Jettings"
      :rows="jettings"
      :columns="columns"
      row-key="name"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
    >
    <template v-slot:top>
        <q-btn color="green" label="Add" @click="showDialog(0)"/>
        <q-btn class="q-ml-sm" color="green" label="Normal" @click="getJettingsInState('Cleaning')"/>
        <q-btn class="q-ml-sm" color="orange" label="Recleaning" @click="getJettingsInState('Recleaning')"/>
        <q-btn class="q-ml-sm" color="red" label="Heavy" @click="getJettingsInState('Heavy')"/>
        <q-btn class="q-ml-sm" color="dark" label="Records" @click="getJettings()"/>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props">
            {{ props.row.id }}
          </q-td>
          <q-td key="dateDisplay" :props="props">
            <a
              class="text-primary"
              @click="showDialog(props.row.id)"
            >
              {{ props.row.dateDisplay }}
            </a>
          </q-td>
          <q-td key="lineNumber" :props="props">
            {{ props.row.lineNumber }}
          </q-td>
          <q-td key="status" :props="props">
              <q-badge :color="statusJetting(props.row.status)">
                {{ props.row.status }}
              </q-badge>
          </q-td>
          <q-td key="timesJetted" :props="props">
            {{ props.row.timesJetted }}
          </q-td>
          <q-td key="length" :props="props">
            {{ props.row.length }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="jettingDialog"
    >
      <q-card style="width: 500px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="entryPost()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-12">
                <q-input
                  v-model="jetting.dateDisplay"
                  :dense="true"
                  label="Date & Time"
                  outlined
                />
              </div>
              <div class="col-12">
                <q-input
                  v-model="jetting.lineNumber"
                  :dense="true"
                  label="Line Number"
                  outlined
                />
              </div>
              <div class="col-12">
                <q-input
                  v-model="jetting.timesJetted"
                  :dense="true"
                  label="Times Jetted"
                  outlined
                />
              </div>
              <div class="col-12">
                <q-input
                  v-model="jetting.length"
                  :dense="true"
                  label="Length"
                  outlined
                />
              </div>
              <div class="col-12">
                <q-input
                  v-model="jetting.notes"
                  :dense="true"
                  label="Notes"
                  type="textarea"
                  outlined
                />
              </div>
            </div>
            <div class="q-mt-sm" />
            <div>
              <UserCard :user="jetting.applicationUser"/>
            </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="submitDisable" :loading="submitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="entryDelete">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import axios from '../../axios'
import UserCard from '../util/UserCard.vue'

export default {
  name: 'Jettings',
  components: {
    UserCard,
  },
  props: {
  },
  data() {
    return {
      notify: useQuasar(),
      jettings: [],
      jetting: {},
      submitDisable: false,
      jettingDialog: false,
      projectId: this.$route.params.id,
      filter: '',
      columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'dateDisplay', align: 'center', label: 'Date', field: 'dateDisplay', sortable: true },
        { name: 'lineNumber', align: 'center', label: 'Line Number', field: 'lineNumber', sortable: true },
        { name: 'status', align: 'center', label: 'Type', field: 'status', sortable: true },
        { name: 'timesJetted', align: 'center', label: 'TImes Jetted', field: 'timesJetted', sortable: true },
        { name: 'length', align: 'center', label: 'Length', field: 'length', sortable: true },
      ],
    }
  },
  computed: {
  },
  created() {
    this.getJettings()
  },
  methods: {
    statusJetting(input) {
      switch (input) {
        case 'Normal': return 'green'
        case 'Recleaning': return 'orange'
        case 'Heavy': return 'red'
        default: return 'dark'
      }
    },
    async getJettings() {
      axios
        .get(`/api/CCTV/Jettings/ProjectId/${this.projectId}`)
        .then(response => { this.jettings = response.data })
    },
    async getJetting(id) {
      await axios
        .get(`/api/CCTV/Jettings/Id/${id}`)
        .then(response => { this.jetting = response.data })
    },
    async showDialog(id) {
      await this.getJetting(id)
      this.jettingDialog = true
    },
    async entryPost() {
      this.jetting.projectId = this.projectId
      this.submitDisable = true
      await axios
        .post('/api/CCTV/Jettings', this.jetting)
        .then(response => {
          this.jetting = response.data
          this.notify.notify({
            message: 'Saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      this.submitDisable = false
      await this.getJettings()
    },
    async entryDelete() {
      /* eslint-disable no-unused-vars */
      await axios
        .delete(`/api/CCTV/Jettings/Id/${this.jetting.id}`)
        .then(response => {
          this.jettingDialog = false
          this.notify.notify({
            message: 'Entry Deleted',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getJettings()
      /* eslint-enable no-unused-vars */
    },
    async getJettingsInState(state) {
      await axios
        // eslint-disable-next-line
        .get(`/api/CCTV/Jettings/ProjectId/${this.projectId}/${state}`)
        .then(response => {
          this.jettings = response.data
        })
    },
  },
}
</script>