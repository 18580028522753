<template>
    <q-card flat bordered class="my-card" v-if="user !== null">
        <q-card-section>
            <div >{{ user.firstName }} {{ user.lastName }}</div>
            <div class="text-weight-light">{{ user.positionTitle }}</div>
        </q-card-section>
    </q-card>
</template>

<script>

export default {
  name: 'UserCard',
  components: {
  },
  props: {
    user: {},
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
}
</script>