<template>
  <div>
    <q-card flat bordered class="my-card" v-if="stats !== null">
      <q-card-section>
        <div class="text-h6">Manholes - {{ stats.gisCount }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <span>Complete: {{ stats.scanned.count }} ({{ stats.scanned.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.scanned.percentage" color="green" class="q-mt-sm q-mb-sm"/>
        
        <span>Complete Rescanned: {{stats.rescanned.count}} ({{ stats.rescanned.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.rescanned.percentage" color="green" class="q-mt-sm q-mb-sm"/>
        
        <span>Incomplete - Need Rescan: {{stats.incompleteNeedRescan.count}} ({{ stats.incompleteNeedRescan.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.incompleteNeedRescan.percentage" color="orange" class="q-mt-sm q-mb-sm"/>
        
        <span>Incomplete - Need City: {{stats.incompleteNeedCty.count}} ({{ stats.incompleteNeedCty.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.incompleteNeedCty.percentage" color="orange" class="q-mt-sm q-mb-sm"/>
        
        <span>Aborted: {{stats.aborted.count}} ({{ stats.aborted.percentageDisplay }})</span>
        <q-linear-progress rounded size="15px" :value="stats.aborted.percentage" color="red" class="q-mt-sm"/>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  name: 'StatsManholes',
  components: {
  },
  props: {
  },
  data() {
    return {
      projectId: this.$route.params.id,
      stats: null,
    }
  },
  computed: {
  },
  created() {
    this.getManholeStats()
  },
  methods: {
    async getManholeStats() {
      await axios
        .get(`/api/CCTV/Manholes/ProjectId/${this.projectId}/Statistics`)
        .then(response => {
          this.stats = response.data
        })
    }
  },
}
</script>