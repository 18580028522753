<template>
    <div>
    <q-table
      title="GIS Lines"
      :rows="gislines"
      :columns="columns"
      row-key="name"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
    >
    <template v-slot:top>
        <q-btn color="green" label="Add" @click="showDialog(0)"/>
        <q-btn class="q-ml-sm" color="green" label="Import" @click="showImportDialog()"/>
        <q-btn class="q-ml-sm" color="green" label="Template" @click="downloadImport()"/>
        <q-btn class="q-ml-sm" color="red" label="Delete" v-touch-hold:5000.mouse="deleteAllGis">
          <q-tooltip
            transition-show="flip-right"
            transition-hide="flip-left"
          >
            Hold for 5 sec.
          </q-tooltip>
        </q-btn>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props">
            {{ props.row.id }}
          </q-td>
          <q-td key="quarterSection" :props="props">
            {{ props.row.quarterSection }}
          </q-td>
          <q-td key="lineNumber" :props="props">
            <a
              class="text-primary"
              @click="showDialog(props.row.id)"
            >
              {{ props.row.lineNumber }}
            </a>
          </q-td>
          <q-td key="gisLength" :props="props">
            {{ props.row.gisLength }}
          </q-td>
          <q-td key="gisDiameter" :props="props">
            {{ props.row.gisDiameter }}
          </q-td>
          <q-td key="gisMaterial" :props="props">
            {{ props.row.gisMaterial }}
          </q-td>
          <q-td key="gisUsMh" :props="props">
            {{ props.row.gisUsMh }}
          </q-td>
          <q-td key="gisDsMh" :props="props">
            {{ props.row.gisDsMh }}
          </q-td>
          <q-td key="gisRowStatus" :props="props">
            {{ props.row.gisRowStatus }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="gislineDialog"
    >
      <q-card style="width: 500px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postGisLine()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-6">
                <q-input
                  v-model="gisline.quarterSection"
                  :dense="true"
                  label="Quarter Section"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gisline.lineNumber"
                  :dense="true"
                  label="Line Number"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gisline.gisLength"
                  :dense="true"
                  label="Length"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gisline.gisDiameter"
                  :dense="true"
                  label="Diameter"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gisline.gisUsMh"
                  :dense="true"
                  label="USMH"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gisline.gisDsMh"
                  :dense="true"
                  label="DSMH"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gisline.gisMaterial"
                  :dense="true"
                  label="Material"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="gisline.gisRowStatus"
                  :dense="true"
                  label="ROW"
                  outlined
                />
              </div>
            </div>
              <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" v-touch-hold:3000.mouse="entryDelete">
                <q-tooltip
                  transition-show="flip-right"
                  transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="importDialog"
    >
      <q-uploader
        @uploaded="getGisLines"
        :url="aurl + 'api/CCTV/GISLines/Import/ProjectId/' + projectId"
        :headers="[{name: 'Authorization', value: getToken()}]"
        style="max-width: 300px"
      />
    </q-dialog>
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import axios from '../../axios'
import fileDownload from 'js-file-download'

const url = axios.defaults.baseURL

export default {
  name: 'GISLines',
  components: {
  },
  props: {
  },
  data() {
    return {
      notify: useQuasar(),
      gislines: [],
      gisline: {},
      aurl: url,
      gislineDialog: false,
      importDialog: false,
      projectId: this.$route.params.id,
      sheet: {},
      filter: '',
      columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'quarterSection', align: 'center', label: 'QS', field: 'quarterSection', sortable: true },
        { name: 'lineNumber', align: 'center', label: 'Number', field: 'lineNumber', sortable: true },
        { name: 'gisLength', align: 'center', label: 'Length', field: 'gisLength', sortable: true },
        { name: 'gisDiameter', align: 'center', label: 'Diameter', field: 'gisDiameter', sortable: true },
        { name: 'gisMaterial', align: 'center', label: 'Material', field: 'gisMaterial', sortable: true },
        { name: 'gisUsMh', align: 'center', label: 'USMH', field: 'gisUsMh', sortable: true },
        { name: 'gisDsMh', align: 'center', label: 'DSMH', field: 'gisDsMh', sortable: true },
        { name: 'gisRowStatus', align: 'center', label: 'ROW', field: 'gisRowStatus', sortable: true },
      ],
    }
  },
  computed: {
  },
  created() {
    this.getGisLines()
  },
  methods: {
    async getGisLines() {
      axios
        .get(`/api/CCTV/GISLines/ProjectId/${this.projectId}`)
        .then(response => { this.gislines = response.data })
    },
    async getGisLine(id) {
      if (id !== 0) {
        axios
          .get(`/api/CCTV/GISLines/Id/${id}`)
          .then(response => { this.gisline = response.data })
      } else {
        this.gisline = {}
      }
    },
    async showDialog(id) {
      await this.getGisLine(id)
      this.gislineDialog = true
    },
    async entryDelete() {
      /* eslint-disable no-unused-vars */
      await axios
        .delete(`/api/CCTV/GISLines/Id/${this.gisline.id}`)
        .then(response => {
          this.gislineDialog = false
          this.notify.notify({
            message: 'Entry deleted',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getGisLines()
      /* eslint-enable no-unused-vars */
    },
    async postGisLine() {
      this.gisline.projectId = this.projectId
      await axios
        .post('/api/CCTV/GISLines', this.gisline)
        .then(response => {
          this.gisline = response.data
          this.notify.notify({
            message: 'Work saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getGisLines()
    },
    async deleteAllGis() {
      await axios
        .delete(`/api/CCTV/GISLines/ProjectId/${this.projectId}`)
        .then(response => {
          console.log(response)
          this.notify.notify({
            message: 'Data Deleted',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getGisLines()
    },
    showImportDialog() {
      this.importDialog = true
    },
    getToken() {
      return localStorage.getItem("access_token");
    },
    async downloadImport() {
      await axios
        .get('/api/CCTV/GISLines/ImportTemplate', {
          responseType: 'blob',
        })
        .then(res => {
          fileDownload(res.data, "GIS Import Lines Template.xlsx")
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
    },
  },
}
</script>